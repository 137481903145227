<template>
  <div class="all_main_wrap">
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">
          <router-link class="crumbs_item" tag="a" to="/admin/PromotionManage/couponManage">优惠券管理 / </router-link>
          <span class="crumbs_item crumbs_last">{{operation==1?'新建折扣券':operation==2?'编辑折扣券':'折扣券详情'}}</span>
        </span>
      </div>
    </div>

    <div class="title-blod">基本信息</div>

    <div class="all_content_box">
      <!-- <p>左右分布式</p> -->
      <div class="L_R_inner">
        <span class="all_left_name all_required">折扣券名称：</span>
        <div class="right_Div">
          <a-input class="all_input" v-model="data.name" :max-length="10" placeholder="如卡罗兰纳优惠券，最多10个字" />
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name all_required">使用时间：</span>
        <a-radio-group name="radioGroup" v-model="data.times" @change="onChange">
          <div class="box-first">
            <div class="right_Div">
              <a-radio :value="2">限时</a-radio>
              <a-date-picker :disabled="data.times != 2" valueFormat="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss" 
              :disabled-date="disabledDate" @change="changeStartTime" placeholder="选择开始时间" v-model="data.startTime"  />
            </div>
            <div class="right_Div">
              至
            </div>
            <div class="right_Div">
              <a-date-picker :disabled="data.times != 2" valueFormat="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss" 
              :disabled-date="disabledDate" @change="changeStartTime" placeholder="选择结束时间" v-model="data.endTime"  />
            </div>
            <div class="right_Div">
                <a-radio :value="1">不限</a-radio>
            </div>
          </div>
        </a-radio-group>
      </div>

      <div class="L_R_inner">
        <span class="all_left_name all_required">使用范围：</span>
        <div class="right-box">
          <a-radio-group name="radioGroup" v-model="data.scope" @change="onChangeScope">
            <div class="box-first">
              <div class="right_Div">
                <a-radio :value="1">全场通用</a-radio>
              </div>

              <div class="right_Div">
                <a-radio :value="2">商品类型适用</a-radio>
                <a-select size='large' mode="multiple" placeholder="请选择商品类型" :disabled="data.scope != 2" v-model="data.categoryType">
                  <a-icon slot="suffixIcon" type="caret-down" />
                    <a-select-option v-for="item in goodList" :key="item.id" :value="item.id">
                      {{ item.classify }}
                    </a-select-option>
                </a-select>
              </div>

              <div class="right_Div">
                  <a-radio :value="3">指定商品适用</a-radio>
              </div>
            </div>

            <div class="Up_Down_inner">
              <span class="all_left_name" v-if="data.scope == 3">
                <!-- <a-radio :value="3">指定商品适用</a-radio> -->
                <div>商品适用</div>
                <a-button type="primary" class="btn"  @click="popupFilling(1)">新建</a-button>
              </span>
              <a-table class="table-template" v-if="ctableData!=''" :rowKey="(item) => item.productId" :columns="ccolumns" :data-source="ctableData" :pagination="{
                  current: pageNumber,
                  pageSize: pageSize,
                  pageSizeOptions: ['5','10', '20', '30', '50', '100'],
                }" @change="onPage">
                <template slot="index" slot-scope="item, row, index">
                  {{ (pageNumber - 1) * pageSize + index + 1 }} 
                </template>

                <template slot="operation" slot-scope="item, row">
                  <span class="blueText">
                    <span  @click="deleteGoods(row,1)">删除</span>
                  </span>
                </template>
              </a-table>
            </div>
            <div class="Up_Down_inner">
              <span class="all_left_name" v-if="data.scope == 3">
                <!-- <a-radio :value="4">指定套餐适用</a-radio> -->
                <div>套餐适用</div>
                <a-button type="primary" class="btn" @click="popupFilling(2)">新建</a-button>
              </span>
              <a-table class="table-template" v-if="ptableData!=''" :rowKey="(item) => item.packageId" :columns="pcolumns" :data-source="ptableData" :pagination="{
                  current: pageNumbers,
                  pageSize: pageSizes,
                  pageSizeOptions: ['5','10', '20', '30', '50', '100'],
                }" @change="onPages">
                <template slot="index" slot-scope="item, row, index">
                   {{ (pageNumbers - 1) * pageSizes + index + 1 }}
                </template>

                <template slot="operation" slot-scope="item, row">
                  <span class="blueText">
                    <span @click="deleteGoods(row,2)">删除</span>
                  </span>
                </template>
              </a-table>
            </div>
          </a-radio-group>
        </div>
      </div>

    </div>

    <div class="title-blod">折扣设置</div>

    <div class="all_content_box">
      <div class="L_R_inner">
        <span class="all_left_name all_required">打折方式：</span>
        <div class="right_Div">
          <a-radio-group name="radioGroup" v-model="data.discountType">
            <a-radio :value="1">
              件数打折
            </a-radio>
            <a-radio :value="2">
              金额打折
            </a-radio>
          </a-radio-group>
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name all_required">折扣：</span>
        <div class="right_Div">
          打
          <a-input v-model="data.discount" class="all_input else_input left" placeholder="" />折
        </div>
      </div>

      <div class="redtext">提示：请输入小于10的数字</div>

      <div class="L_R_inner">
        <span class="all_left_name all_required">使用门槛：</span>
        <div class="right_Div">
          满
          <a-input-number id="inputNumber" class="all_input else_input left" v-model="data.discountPrice" :min="0" :max="999999999"/>{{data.discountType == 1?'件':'元'}}
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name all_required">领取时间：</span>
        <div class="right_Div">
          <a-date-picker valueFormat="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss" @change="changeLedTime" placeholder="选择开始时间" v-model="data.startLed"/>
        </div>
        <div class="right_Div">
          至
        </div>
        <div class="right_Div">
          <a-date-picker valueFormat="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss" @change="changeLedTime" placeholder="选择结束时间" v-model="data.endLed"  />
        </div>
      </div>
      <!-- <div class="L_R_inner">
        <span class="all_left_name all_required">优惠券使用：</span>
        <a-radio-group name="radioGroup" v-model="data.overly">
          <div class="box-first">
            <div class="right_Div">
              <a-radio :value="1">允许叠加</a-radio>
            </div>
            <div class="right_Div">
                <a-radio :value="0">不允许叠加</a-radio>
            </div>
          </div>
        </a-radio-group>
      </div> -->
    </div>

    <div class="button">
      <a-button v-if="operation!=3" type="primary" class="btn" @click="onSave">确认创建</a-button>
      <a-button class="all_boder_btn btn" @click="$router.go(-1)">取消</a-button>
    </div>

    <a-modal v-model="category" width='1000px' :title="popup==1?'选择商品':'选择套餐'" :maskClosable='false' :centered='true' :closable='false'>
      <div class="search-box">
        <a-select v-if="popup==1" class="search-input" placeholder="请选择类型" v-model="categorytype" allowClear>
          <a-icon slot="suffixIcon" type="caret-down" />
            <a-select-option v-for="item in cgoodList" :key="item.id" :value="item.id">
              {{ item.classify }}
            </a-select-option>
        </a-select>
        <a-input class="search-input" placeholder="请输入名称" v-model="categoryname" allowClear/>
        <a-button class="search-btn" type="primary" @click="onSearch()">搜索</a-button>
      </div>

      <div class="table-box">
        <a-table class="table-template" :row-selection="{
       columnTitle:'选择',
       selectedRowKeys: selectedRowKey,
       onChange: select,
      }" :rowKey="(item) => popup==1?item.productId:item.packageId" :columns="popupcolumns" :data-source="categoryData" :pagination="{
        total: ctotal,
        current: cpageNumber,
        pageSize: cpageSize,
        showTotal: (res) => `共${ctotal}条`,
      }" bordered @change="oncPage">
          <template slot="num">
            {{popup==1?'商品名称':'套餐名称'}}
          </template>
          <template slot="index" slot-scope="item, row, index">
            {{ (cpageNumber - 1) * cpageSize + index + 1 }} 
          </template>
          <template slot="productType" slot-scope="item">
            <!-- 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.其他 -->
            <span v-if="item==1">课程</span>
            <span v-else-if="item==2">图书</span>
            <span v-else-if="item==3">教具</span>
            <span v-else-if="item==4">模拟考试</span>
            <span v-else-if="item==5">电子照片</span>
            <span v-else-if="item==6">延期</span>
            <span v-else-if="item==7">补考</span>
            <span v-else-if="item==8">直播</span>
            <span v-else-if="item==9">其他</span>
            <span v-else>-</span>
          </template>
        </a-table>

        <a-table class="table-template" :rowKey="(item) => popup==1?item.productId:item.packageId" :columns="popup==1?cselectcolumns:pselectcolumns" :data-source="selectData" :pagination="{
        pageSize: spageSize,
      }">
          <template slot="num">
            当前已选中 <span class="blueText">{{selectData == null?'0':selectData.length}}</span> 个{{popup==1?'商品':'套餐'}}
          </template>
          <template slot="operation" slot-scope="item, row">
            <span class="blueText"  @click="deleteGoods(row)">清除</span>
          </template>
        </a-table>
      </div>

      <template slot="footer">
        <a-button type="primary" @click="categoryOk">确认</a-button>
        <a-button @click="categoryCancel">取消</a-button>
      </template>

    </a-modal>
  </div>
</template>

<script>
  import moment from "moment";
  // 商品列表
  const categorycolumns = [{
      title: "序号",
      align: "center",
      dataIndex: "index",
      scopedSlots: {
        customRender: "index",
      },
    },
    {
      title: "商品类型",
      align: "center",
      dataIndex: "productType",
      scopedSlots: {
        customRender: "productType",
      },
    },
    {
      align: "center",
      dataIndex: "productName",
      scopedSlots: {
        title: "num",
      },
    },
  ];
  // 套餐列表
  const packagecolumns = [{
      title: "序号",
      align: "center",
      dataIndex: "index",
      scopedSlots: {
        customRender: "index",
      },
    },
    {
      align: "center",
      dataIndex: "packageName",
      scopedSlots: {
        title: "num",
      },
    },
  ];
  // 商品选中
  const cselectcolumns = [{
      align: "center",
      dataIndex: "productName",
      width: 120,
      colSpan: 2,
      scopedSlots: {
        customRender: "productName",
        title: "num",
      },
    },
    {
      title: "操作",
      align: "center",
      colSpan: 0,
      width: 20,
      dataIndex: "operation",
      scopedSlots: {
        customRender: "operation",
      },
    },
  ];
  // 套餐选中
  const pselectcolumns = [{
      align: "center",
      dataIndex: "packageName",
      width: 120,
      colSpan: 2,
      scopedSlots: {
        customRender: "packageName",
        title: "num",
      },
    },
    {
      title: "操作",
      align: "center",
      colSpan: 0,
      width: 20,
      dataIndex: "operation",
      scopedSlots: {
        customRender: "operation",
      },
    },
  ];
  // 商品展示
  const ccolumns = [{
      title: "序号",
      align: "center",
      dataIndex: "index",
      scopedSlots: {
        customRender: "index",
      },
    },
    {
      title: "商品名称",
      align: "center",
      dataIndex: "productName",
    },
    {
      title: "操作",
      align: "center",
      dataIndex: "operation",
      scopedSlots: {
        customRender: "operation"
      },
    },
  ];
  // 套餐展示
  const pcolumns = [{
     title: "序号",
     align: "center",
     dataIndex: "index",
     scopedSlots: {
       customRender: "index",
     },
   },
   {
     title: "套餐名称",
     align: "center",
     dataIndex: "packageName",
   },
   {
     title: "操作",
     align: "center",
     dataIndex: "operation",
     scopedSlots: {
       customRender: "operation"
     },
   },
  ];
   export default {
    // 可用组件的哈希表
    components: {},
    // 接收传值
    props: {},
    // 数据对象
    data() {
      return {
        //商品列表
        ccolumns,
        ctableData: [],
        pcolumns,
        ptableData: [],
        // lcolumns,
        // ltableData: [],
        pageNumber: 1, //页码
        pageSize: 5, // 页数
        pageNumbers: 1, // 页码
        pageSizes: 5, // 页数
        //商品弹窗
        popup: '',
        category: false,
        categoryname:'',
        categorytype: undefined,
        categoryData: [],
        ctotal: 0,
        cpageNumber: 1, //页码
        cpageSize: 5, // 页数

        popupcolumns: this.categorycolumns,// 选择
        categorycolumns,// 选择商品
        packagecolumns,// 选择套餐
        cselectcolumns,// 已选商品
        pselectcolumns,// 已选套餐

        selectedRowKey: [],
        selectedRowKeys: [],
        selectedRowKeyr: [],

        selectData: this.cselectData,
        cselectData: [],
        pselectData: [],
        spageNumber: 1, // 页码
        spageSize: 5, // 页数

        //商品类别
        goodList:[
          { id: 1, classify:'课程'},
          { id: 2, classify:'图书'},
          { id: 3, classify:'教具'},
          { id: 4, classify:'模拟考试'},
          { id: 5, classify:'电子照片'},
          { id: 6, classify:'延期'},
          { id: 7, classify:'补考'},
          { id: 8, classify:'直播'},
        ],
        cgoodList:[
          { id: 0, classify:'全部'},
          { id: 1, classify:'课程'},
          { id: 2, classify:'图书'},
          { id: 3, classify:'教具'},
          { id: 4, classify:'模拟考试'},
          { id: 5, classify:'电子照片'},
          { id: 6, classify:'延期'},
          { id: 7, classify:'补考'},
          { id: 8, classify:'直播'},
        ],
        data:{
          // astrict: '',//是否限领
          categoryType: [],
          // code: '',//优惠劵编码
          couponId: '',
          discount: '',
          discountType: '',
          // enable: '',//是否启用
          endLed: null,
          endTime: null,
          name: '',
          noVolumes: '',
          // overly: '',
          productList: [],
          // residue: '',//已发放量
          scope: '',
          startLed: null,
          startTime: null,
          state: 1,
          times: '',
          type: 3,
          volumes: '',
          categoryArr: [],
        },
      };
    },
    // 事件处理器
    methods: {
      //日期选择框
      moment,
      changeStartTime(x, s) {
        this.startTime = s;
      },
      changeEndTime(x, s) {
        this.endTime = s;
      },
      changeLedTime(x, s) {
        this.startLed = s;
      },
      changeLedTime(x, s) {
        this.endLed = s;
      },
      onChange(e) {
        // console.log(`checked = ${e.target.checked}`);
      },
      onChangeScope(e){
        if(e.target.value != 2){
          this.data.categoryType = [];
        }
      },
      disabledDate (current) {
        return current && current < moment().subtract(1, 'days').endOf('day')
      },
      onPage(e) {
        this.pageNumber = e.current;
        this.pageSize = e.pageSize;
        // this.popupFilling(this.popup);
      },
      onPages(e) {
        this.pageNumbers = e.current;
        this.pageSizes = e.pageSize;
      },
      //商品弹窗
      oncPage(e) {
        this.cpageNumber = e.current;
        this.cpageSize = e.pageSize;
        this.popupFilling(this.popup,true);
      },
      onSearch() {
        this.popupFilling(this.popup);
      },
     categoryOk(e) {
        this.category = false;
        if(this.popup == 1){
          this.ctableData = JSON.parse(JSON.stringify(this.selectData));
        }else if(this.popup == 2){
          this.ptableData = JSON.parse(JSON.stringify(this.selectData));
        }
        this.cpageNumber = 1;
      },
      categoryCancel(e) {
        this.cpageNumber = 1;
        this.category = false;
      },
      onMap(){
        if(this.data.productList != null){
          this.cselectData = JSON.parse(JSON.stringify(this.ctableData));
          this.pselectData = JSON.parse(JSON.stringify(this.ptableData));
          this.selectedRowKeys = this.ctableData.map(x => {
            return x.productId
          })
          this.selectedRowKeyr = this.ptableData.map(x => {
            return x.packageId
          })
        }
      },
      popupFilling(e,forPage) {
        this.category = true;
        this.onMap();
        this.popup = e;
        if(e == 1){
          this.popupcolumns = this.categorycolumns;
          if(!forPage){
            this.selectData = this.cselectData;
            this.selectedRowKey = this.selectedRowKeys;
          }
          this.onCategory();
        }else if(e == 2){
          this.popupcolumns = this.packagecolumns;
          if(!forPage){
            this.selectData = this.pselectData;
            this.selectedRowKey = this.selectedRowKeyr;
          }
          this.onPackage();
        }
      },
      onCategory(){
        this.$ajax({
          url: "/hxclass-management/product-archives/manage/select-win",
          params: {
            noGround: 1,
            name: this.categoryname,
            type: this.categorytype==0?'':this.categorytype,
            page: this.cpageNumber,
            size: this.cpageSize,
          },
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.ctotal = res.data.total;
            this.categoryData = res.data.records;
          } else {
            this.$message.error(res.message);
          }
        });
      },
      onPackage(){
        this.$ajax({
          url: "/hxclass-management/packages/box",
          params: {
            packageName: this.categoryname,
            pageNum: this.cpageNumber,
            pageSize: this.cpageSize,
          },
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.ctotal = res.data.total;
            this.categoryData = res.data.records;
          } else {
            this.$message.error(res.message);
          }
        });
      },
      select(selectedRowKey){
        this.selectedRowKey = selectedRowKey;
        let ary = this.categoryData;
          for (let index = 0; index < selectedRowKey.length; index++) {
          for (let j = 0; j < ary.length; j++) {
            if (ary[j].productId == selectedRowKey[index] || ary[j].packageId == selectedRowKey[index]) {
              this.selectData[index] = ary[j];
            }
          }
        }
      },
      deleteGoods(e,x) {
        let nbr = -1;
        if(x){
          if(x == 1){
            nbr = this.ctableData.indexOf(e);
            this.cselectData.splice(nbr, 1);
            this.ctableData.splice(nbr, 1);
            this.selectedRowKeys.splice(nbr, 1);
          }else if(x == 2){
            nbr = this.ptableData.indexOf(e);
            this.pselectData.splice(nbr, 1);
            this.ptableData.splice(nbr, 1);
            this.selectedRowKeyr.splice(nbr, 1)
          }
        }else{
          nbr = this.selectData.indexOf(e);
          this.selectData.splice(nbr, 1);
          this.selectedRowKey.splice(nbr, 1);
        } 
      },
      getDetail() {
        this.$ajax({
          url: "/hxclass-management/coupon-manage/manage/select-one/" + this.id,
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.data = res.data;
            if(this.data.categoryType==0){
              this.data.categoryType = undefined;
            }else{
              this.data.categoryType = this.data.categoryArr;
            }
            this.data.productList.forEach((item) => {
              if (item.productType==1) {
                this.ctableData.push(item)
              } else {
                item.packageId = item.productId;
                item.packageName = item.productName;
                this.ptableData.push(item)
              }
            });
            this.onMap();
          } else {
            this.$message.error(res.message);
          }
        });
      },
      onSave(){
        this.data.productList = []
          // console.log(String(this.data.categoryType));
        // his.data.categoryType = String(this.data.categoryType);
        // if(this.data.categoryType && this.data.categoryType != ''){
          // this.data.categoryType = String(this.data.categoryType);
        // }
        // console.log(this.data.categoryType);
        this.ctableData.forEach(item =>{
           this.data.productList.push({
              productId: item.productId,
              productType: 1,
           });
        });
        this.ptableData.forEach(item =>{
           this.data.productList.push({
              productId: item.packageId,
              productType: 2,
           })
        });
        if (this.data.categoryType == undefined) {
          this.data.categoryType = [];
        }
        // console.log(this.data.categoryType);
        if (!this.data.name) {
          this.$message.warn("请输入折扣券名称");
          return false;
        }
        if (!this.data.times) {
          this.$message.warn("请选择使用时间");
          return false;
        }
        if(this.data.times == 1){
          this.data.startTime = null;
          this.data.endTime= null;
        }else if(this.data.times == 2){
           if (!this.data.startTime) {
             this.$message.warn("请选择使用开始时间");
             return false;
           }
           if (!this.data.endTime) {
             this.$message.warn("请选择使用结束时间");
             return false;
           }
        }
         if (!this.data.scope) {
          this.$message.warn("请选择使用范围");
          return false;
        }
        if (this.data.scope == 2 && this.data.categoryType.length == 0) {
          this.$message.warn("请选择商品类型");
          return false;
        }
        if (!this.data.discountType) {
          this.$message.warn("请选择打折方式");
          return false;
        }
        if (!this.data.discount) {
          this.$message.warn("请输入折扣");
          return false;
        }
        this.data.discount = (this.data.discount*1).toFixed(1)
        if(!this.$regular.discount_number.reg.test(this.data.discount)){
          this.$message.warning("折扣请输入10以内数字");
          return false
        }
        if (!this.data.discountPrice) {
          this.$message.warn("请输入使用门槛");
          return false;
        }
        if (!this.data.startLed) {
          this.$message.warn("请选择领取开始时间");
          return false;
        }
        if (!this.data.endLed) {
          this.$message.warn("请选择领取结束时间");
          return false;
        }
        if(this.moment(this.data.endTime).format("YYYY-MM-DD HH:mm:ss") < this.moment(this.data.endLed).format("YYYY-MM-DD HH:mm:ss")){
        this.$message.warning('优惠券领取结束时间应早于使用结束时间');
        return
      }
        // if (this.data.overly != 1 && this.data.overly != 0) {
        //   this.$message.warn("请选择优惠券使用方式");
        //   return false;
        // }
        this.data.categoryType = String(this.data.categoryType);
        var url = "";
        var method = "";
        if (this.id) {
          // 编辑
          this.data.couponId = this.id;
          url = "/hxclass-management/coupon-manage/manage/update";
          method = "put";
        } else {
          // 新增
          url = "/hxclass-management/coupon-manage/manage/save";
          method = "post";
        }
        this.$ajax({
          url: url,
          method: method,
          params: this.data,
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.$router.push("/admin/PromotionManage/couponManage");
          }else{
            this.$message.warning(res.message)
          }
        });
      },
    },
    // 生命周期-实例创建完成后调用
    created() {
      this.operation = this.$route.query.operation;
      if (this.operation != 1) {
        this.id = this.$route.query.id;
        this.getDetail();
      }
    },
    // 生命周期-实例挂载后调用
    mounted() {},
    // 生命周期-实例销毁离开后调用
    destroyed() {},
    // 计算属性监听
    computed: {},
    // 自定义的侦听器
    watch: {},
  };

</script>

<style lang="less" scoped>
/deep/.ant-input-number-input {
    height: 40px;
    text-align: center;
}
.all_main_wrap {
  a {
    color: #333333;
  }

  .title-blod {
    margin-top: 16px;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 16px;
  }

  .all_content_box {
    .redtext{
      margin-bottom: 20px;
      margin-left: 182px;
      font-size: 12px;
      color: #f90000;
    }

     .L_R_inner:nth-child(2){
       margin-bottom: 4px;
     }

    .L_R_inner {
      margin: 24px;
      .all_left_name {
        width: 110px;
        text-align: right;
      }

      .right_Div {
        .all_input {
          width: 337px;
        }
        /deep/.ant-input {
          height: 37px;
        }
        .else_input {
          margin-right: 12px;
          width: 140px;
        }
        .left {
          margin-left: 12px;
        }
      }
      .box-first {
          // width: 471px;
          .right_Div {
            display: inline-block;
            line-height: 37px;
            .ant-select {
              width: 166px;
            }
          }
        }

      .right-box {
        .box-first {
          width: 471px;
          .right_Div {
            display: inline-block;
            line-height: 37px;
            .ant-select {
              width: 324px;
            }
          }
        }
        .Up_Down_inner {
          margin-left: 20px;
          .all_left_name {
            display: flex;
            align-items: center;
            margin-top: 24px;
            width: 450px;

            .btn {
              margin: 0 0 0 300px;
            }
          }
        }
      }
    }
  }

  .button {
    .btn {
      margin-left: 20px;
    }
  }
}
/deep/.ant-calendar-picker{
  width: 190px;
}
/deep/.ant-modal-body {
  overflow: hidden;
}
/deep/.ant-modal-title {
  text-align: center;
  color: #000000;
}
/deep/.ant-modal-header {
  border-bottom: none;
}
/deep/.ant-modal-footer {
  text-align: center;
  border-top: none;
}
/deep/ .ant-modal-body {
  padding: 14px 36px 0px;
}
.table-template {
  /deep/.ant-table {
    border: none;
  }
}
.table-box {
  display: flex;
  justify-content: space-between;
  .table-template{
    width: 48%;
  }
}

.search-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  .search-input {
    width: 36%;
  }
}
.picture {
  width: 60px;
}
</style>
